import Dropdown from 'lib/components/dropdown/dropdown'
import TextInput from 'lib/components/text-input/text-input'

function Tags() {
  return (
    <div>
      <h3>Tags</h3>
      <TextInput placeholder="Search for a tag..." />
      <div className="tw-flex tw-items-center tw-justify-center tw-flex-col">
        <img src="/images/states/not-found.svg" alt="No Tags Found" />
        <h5>Oops, no tags were found!</h5>
        <p>Create a new tag by typing in the box above</p>
      </div>
    </div>
  )
}
export default function CompanyTaggingPopover() {
  return (
    <div>
      <Dropdown title="Select tag(s)" label="Tags" openByDefault={false} size="sm">
        <Tags />
      </Dropdown>
    </div>
  )
}
